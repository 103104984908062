
/* eslint-disable */
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@common/src/utils/common";
import dayjs from "dayjs";
import { Table, TableRowsSelect } from "@common/src/utils/decorator";
import BtnRadio from "@/components/scope/btn-radio.vue";
import FilterBar from "@common/src/components/scope/filter-bar.vue";
import { formDownload } from "@common/src/utils/tools";
import echarts from "echarts";
import appCode from "@common/src/utils/app-code";
const statement = namespace("statement");
const base = namespace("base");
const management = namespace("management");
const service = namespace("service-item");
const parts = namespace("parts");
@Component({
  components: { BtnRadio, FilterBar },
  filters: {}
})
@Table("loadListData")
export default class Statement extends Vue {
  @statement.Action getTurnOverPageModelPage;
  @statement.Action getCardMarketList;
  @base.Action getDictionaryList;
  @management.Action getStoreList;
  @service.Action getServiceList;
  @parts.Action getPartsList;

  dayjs = dayjs;
  splitThousands = splitThousands;
  emptyToLine = emptyToLine;
  searchForm: any = {
    storeCode: "", // 门店名称
    area: "", // 所在地区
    stateName: "", // 省级名称
    cityName: "", // 市级名称
    districtName: "", // 县级名称
    timeFrameType: "", // 时间范围类型, date-按日,month-按月
    startTime: "", // 开始时间 格式: 按日为yyyyMMdd, 按月为yyyyMM
    endTime: "", // 结束时间 格式: 按日为yyyyMMdd, 按月为yyyyMM
    businessType: "", // 服务类型，services-服务项目,parts-零配件
    businessCodeList: [] // 服务编码列表
  };
  contentList: any = [];
  dataLastUpdateTime: any = "";
  tabActiveName: any = "1";

  created() {
    this.init();
  }
  // tab点击
  tabHandleClick(e){
    this.$refs.filterBar['reset']()
    // this.loadListData();
  }
  toStatementView(row) {
    let searchForm = this.searchForm;
    let searchList = this.selfData.length ? this.selfData : this.searchList;
    if (row) {
      let dateTip = row.dateTip.split("-").join("");
      searchForm.startTime = dateTip;
      searchForm.endTime = dateTip;
      searchList.forEach(item => {
        if (item.prop === "timeFrameType") {
          item.selectList.forEach(part => {
            part.itemValue = [dateTip, dateTip];
          });
        }
      });
    }
    searchList.forEach(item => {
      if (item.prop === "businessType") {
        item.selectList.forEach(part => {
          if (part.value === "services") {
            part.itemSelectList = this.serviceListCopy;
          } else if (part.value === "parts") {
            part.itemSelectList = this.partsListCopy;
          }
        });
      }
    });
    localStorage.setItem("searchFormData", JSON.stringify(searchForm));
    localStorage.setItem("searchListData", JSON.stringify(searchList));
    this.$router.push("/statement/turnover-view");
  }
  getCurrentTime() {
    // 获取当前时间
    // let timestamp = new Date().getTime() - 24 * 60 * 60 * 1000;
    let timestamp = new Date().getTime();
    let currentTime = new Date(timestamp);
    let year = String(currentTime.getFullYear());
    let month = this.addZero(currentTime.getMonth() + 1);
    let day = this.addZero(currentTime.getDate());
    this.searchForm.timeFrameType = "date";
    this.searchForm.startTime = year + month + "01";
    this.searchForm.endTime = year + month + day;
  }
  addZero(num) {
    return num < 10 ? "0" + num : String(num);
  }
  mounted() {}
  storeList: any = [];
  selfData: any = [];
  serviceList: any = [];
  partsList: any = [];
  serviceListCopy: any = [];
  partsListCopy: any = [];
  get searchList() {
    let storeRow = [
      {
        label: this.$t("v210831.store-name"),
        type: "select-search",
        value: "",
        prop: "storeCode",
        selectList: this.storeList,
        searchFun: this.searchStore
      },
      {
        label: this.$t("setting.region"),
        type: "area",
        value: "",
        labels: [],
        prop: "area",
        anyLevel: true,
        labelWidth: "85px"
      }
    ];
    let timeRow = [
      {
        label: this.$t("v210831.time-frame"),
        type: "select-view",
        value: "",
        prop: "timeFrameType",
        // 时间范围区间必须是同一年
        isSameYear: true,
        selectList: [
          {
            value: "date",
            label: this.$t("v210831.query-by-day"),
            itemValue: "",
            itemType: "daterange",
            itemProp: "dateValue",
            itemValueFormat: "yyyyMMdd"
          },
          {
            value: "month",
            label: this.$t("v210831.query-by-month"),
            itemValue: "",
            itemType: "monthrange",
            itemProp: "dateValue",
            itemValueFormat: "yyyyMM"
          }
        ]
      }
    ];
    let eventRow = [
      {
        label: "项目/零配件筛选",
        type: "select-view",
        value: "",
        prop: "businessType",
        labelWidth: "120px",
        selectList: [
          {
            value: "services",
            label: "服务项目",
            itemValue: [],
            itemType: "select-search",
            itemProp: "businessCodeList",
            itemSelectList: this.serviceList,
            itemSearchFun: this.searchService
          },
          {
            value: "parts",
            label: "零配件",
            itemValue: [],
            itemType: "select-search",
            itemProp: "businessCodeList",
            itemSelectList: this.partsList,
            itemSearchFun: this.searchParts
          }
        ]
      }
    ];
    if (this.tabActiveName == 2) {
      return [...timeRow];
    } else if (appCode === "speedbiz-operation") {
      return [...storeRow, ...timeRow];
    } else if (appCode === "speedbiz") {
      timeRow[0]["labelWidth"] = "120px";
      return [...timeRow, ...eventRow];
    }
    return [];
  }
  searchStore(val = "") {
    this.getStoreList({
      pageNum: 1,
      pageSize: 30,
      storeName: val,
      storeTypeList: ["3", "8", "9"]
    }).then(data => {
      this.storeList = data.data.list
        ? data.data.list.map(item => {
            item.label = item.storeName;
            item.value = item.storeCode;
            return item;
          })
        : [];
    });
  }
  searchService(val = "") {
    this.getServiceList({
      status: "1",
      pageNum: 1,
      pageSize: 10,
      searchKey: val
    }).then(data => {
      let list = data.data.list
        ? data.data.list.filter(item => {
            item.label = item.serviceName;
            item.value = item.serviceCode;
            return !this.serviceListCopy.find(v => v.value === item.value);
          })
        : [];
      this.serviceList = data.data.list
        ? data.data.list.map(item => {
            item.label = item.serviceName;
            item.value = item.serviceCode;
            return item;
          })
        : [];
      this.serviceListCopy = [...list, ...this.serviceListCopy];
    });
  }
  searchParts(val = "") {
    this.getPartsList({
      status: "1",
      pageNum: 1,
      pageSize: 10,
      partsName: val
    }).then(data => {
      let list = data.data.list
        ? data.data.list.filter(item => {
            item.label = item.partsName;
            item.value = item.partsCode;
            return !this.partsListCopy.find(v => v.value === item.value);
          })
        : [];
      this.partsList = this.$safeValue(data, "data.list", []).map(item => {
        item.label = item.partsName;
        item.value = item.partsCode;
        return item;
      });

      this.partsListCopy = [...list, ...this.partsListCopy];
    });
  }
  init() {
    if (appCode === "speedbiz-operation") {
      this.searchStore("");
    } else if (appCode === "speedbiz") {
      this.searchService("");
      this.searchParts("");
    }
    this.getCurrentTime();
    this.loadListData();
  }
  handleExportData() {
    formDownload(`${process.env.VUE_APP_URL_API}/report/turnOver/exportTurnOverPage`, this.searchForm);
  }
  @Table.LoadDataMethod()
  loadListData() {
    if (this.tabActiveName == 1) {
      return this.getTurnOverPageModelPage({
        ...this.searchForm,
        pageNum: this.mix_pageNum,
        pageSize: this.mix_pageSize
      }).then(data => {
        if (data.data.dataLastUpdateTime) {
          this.dataLastUpdateTime = data.data.dataLastUpdateTime;
        }
        this.contentList = data.data.list;
        return data;
      });
    }
    if (this.tabActiveName == 2) {
      return this.getCardMarketList({
        ...this.searchForm,
        pageNum: this.mix_pageNum,
        pageSize: this.mix_pageSize
      }).then(data => {
        if (data.data.dataLastUpdateTime) {
          this.dataLastUpdateTime = data.data.dataLastUpdateTime;
        }
        this.contentList = data.data.list;
        return data;
      });
    }
  }
  @TableRowsSelect.updateMethod
  handleSearch(data, reset) {
    if (reset) {
      this.serviceList.splice(0);
      this.partsList.splice(0);
    }
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "area") {
          this.searchForm.stateName = item.labels[0] || "";
          this.searchForm.cityName = item.labels[1] || "";
          this.searchForm.districtName = item.labels[2] || "";
        } else {
          this.searchForm[item.prop] = item.value;
        }
        if (item.type === "select-view") {
          item.selectList.forEach(val => {
            if (val.value === item.value) {
              if (val.itemProp === "dateValue") {
                this.searchForm.startTime = val.itemValue[0] || "";
                this.searchForm.endTime = val.itemValue[1] || "";
              } else {
                this.searchForm[val.itemProp] = val.itemValue;
              }
            }
          });
        }
      }
    });
    this.selfData = data;
    this.loadListData();
  }
}
